<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <div slot="header">
          <strong>Профіль СПФМ {{ financialModel.name }}</strong>
        </div>
        <b-tabs>
          <b-tab title="Загальна інформація" active>
            <main-profile :financial-model="financialModel" />
          </b-tab>
          <b-tab title="Параметри звітності">
            <reporting-profile :financial-model="financialModel" />
          </b-tab>
          <b-tab title="Ризик-профіль установи" lazy>
            <institutions-risk-profile />
          </b-tab>
          <b-tab
            title="Управління печатками"
            v-if="
              $auth.can($stringConstants('PERMISSION_CREATE_FINANCIAL_MODEL'))
            "
          >
            <management-of-seals-profile />
          </b-tab>
          <b-tab title="Облікові дані ІСЕІ" lazy>
            <isei-data />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import MainProfile from "@/entity/FinancialModels/components/MainProfile.vue";
import ReportingProfile from "@/entity/FinancialModels/components/ReportingProfile.vue";
import InstitutionsRiskProfile from "@/entity/FinancialModels/components/InstitutionsRiskProfile.vue";
import ManagementOfSealsProfile from "@/entity/FinancialModels/components/ManagementOfSealsProfile.vue";
import IseiData from "@/entity/FinancialModels/components/IseiData.vue";

export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$auth.can("view financial model")) next();
      else next({ name: "Page404" });
    });
  },
  name: "FinancialModelForm",
  components: {
    IseiData,
    MainProfile,
    ReportingProfile,
    InstitutionsRiskProfile,
    ManagementOfSealsProfile,
  },
  created() {
    this.$store.dispatch("financialModel/pull", this.$route.params.id);
  },
  beforeDestroy() {
    this.$store.dispatch("financialModel/flush");
  },
  computed: {
    ...mapGetters({
      financialModel: "financialModel/get",
    }),
  },
};
</script>
